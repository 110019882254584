@font-face {
    font-family: 'Plus Jakarta Display';
    src: url('src/plus-jakarta-display/PlusJakartaDisplay-Light.woff2')
            format('woff2'),
        url('src/plus-jakarta-display/PlusJakartaDisplay-Light.woff')
            format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Display';
    src: url('src/plus-jakarta-display/PlusJakartaDisplay-LightItalic.woff2')
            format('woff2'),
        url('src/plus-jakarta-display/PlusJakartaDisplay-LightItalic.woff')
            format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Display';
    src: url('src/plus-jakarta-display/PlusJakartaDisplay-Regular.woff2')
            format('woff2'),
        url('src/plus-jakarta-display/PlusJakartaDisplay-Regular.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Display';
    src: url('src/plus-jakarta-display/PlusJakartaDisplay-Italic.woff2')
            format('woff2'),
        url('src/plus-jakarta-display/PlusJakartaDisplay-Italic.woff')
            format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Display';
    src: url('src/plus-jakarta-display/PlusJakartaDisplay-Medium.woff2')
            format('woff2'),
        url('src/plus-jakarta-display/PlusJakartaDisplay-Medium.woff')
            format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Display';
    src: url('src/plus-jakarta-display/PlusJakartaDisplay-MediumItalic.woff2')
            format('woff2'),
        url('src/plus-jakarta-display/PlusJakartaDisplay-MediumItalic.woff')
            format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Display';
    src: url('src/plus-jakarta-display/PlusJakartaDisplay-Bold.woff2')
            format('woff2'),
        url('src/plus-jakarta-display/PlusJakartaDisplay-Bold.woff')
            format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Display';
    src: url('src/plus-jakarta-display/PlusJakartaDisplay-BoldItalic.woff2')
            format('woff2'),
        url('src/plus-jakarta-display/PlusJakartaDisplay-BoldItalic.woff')
            format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
